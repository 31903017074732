<template>
  <b-card class="chat-card">
    <b-row class="ml-0">
      <h4 class="font-weight-bold card-title">
        Chat
      </h4>
    </b-row>
    <ChatArea2 />
  </b-card>
</template>

<script>
import ChatArea2 from '@/views/apps/chatv2/components/ChatArea2.vue';

export default {
  name: 'ChatList',
  components: { ChatArea2 },
};
</script>

<style lang="scss" scoped>
  .chat-card {
    height: 75vh !important;
    position: relative;
  }
</style>
